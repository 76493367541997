import { MutableRefObject } from "react";

export type TModalTypes = "email" | "experience" | "wellbeing";

export type CustomModalRef = {
	show: (type: TModalTypes, callback?: () => void) => void;
	hide: () => void;
};

export class ModalController {
	static modalRef: MutableRefObject<CustomModalRef>;

	static setModalRef = (ref: any) => {
		this.modalRef = ref;
	};

	static showModal = (type: TModalTypes, callback?: () => void) => {
		this.modalRef.current?.show(type, callback);
	};

	static hideModal = () => {
		this.modalRef.current?.hide();
	};
}
